#aa-Search{
  &.navigation-search {
    padding: unset;
    margin: 8rem 0 0rem;
    flex-direction: column;
  }

  .js-algolia-spinner-overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
  }

  .aa-Panel--stalled .aa-Source {
    filter: unset;
    opacity: unset;
  }
}

.header_navigation:has(#search-navbar .aa-Autocomplete)  #search-navbar {
  &.is-open {
    border: none;
  }

  #search-navdialog {
    z-index: 10;
    max-height: unset;
    min-height: 15rem;
    opacity: 0;
    visibility: hidden;
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    // padding-bottom: 5rem;

    &.is-focused {
      opacity: 1;
      visibility: visible;
    }

    .navigation-search {
      max-width: unset;
      padding-bottom: 0;

      // This rule is required in order to fix spinner displaying when there's no contents. Trust me.
      &:not(:has(.aa-Panel,.hamburger__category:not(.d-none))) {
        padding-bottom: 15rem;
      }

      .aa-Close {
        border: none;
        position: absolute;
        right: 0;
        top: -8rem;
        width: auto;

        .action-bar__button {
          border: 1px solid var(--main-color);
        }
      }

      .site-search {
        display: block;
        max-width: 100rem;
        margin: auto;

        &:has(.aa-InputWrapper .aa-Input:not(:placeholder-shown)) {
          .aa-SubmitButton {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.99 21.01l-5.22-5.3a8.27 8.27 0 001.95-5.35 8.36 8.36 0 10-8.35 8.36 8.26 8.26 0 005.34-1.95l5.21 5.24 1.07-1zM3.51 10.36a6.86 6.86 0 1113.72 0 6.86 6.86 0 01-13.72 0z' fill='%23121212' fill-rule='evenodd'/%3E%3C/svg%3E");
            cursor: pointer;
            pointer-events: fill;

            &.disabled {
              pointer-events: none;
              opacity: 0.5;
            }
          }
        }

        #aa-search-input {
          margin-top: .8rem;
          .aa-Form, .aa-Form:focus-within {
            border-radius: 0;
            border: none;
            box-shadow: none;

            .aa-InputWrapperPrefix {
              margin-right: 0;
              width: auto;
              cursor: pointer;
            }

            .aa-InputWrapper.form-element__input-container {
              order: 0;
              margin-right: 0;

              .form-element__search {
                padding: 1.2rem 2rem;

                &::placeholder {
                  color: var(--black);
                }
              }
            }

            .aa-Input {
              padding: 1rem 2rem;
            }

            .aa-InputWrapperSuffix,
            .aa-InputWrapperPrefix {
              position: absolute;
              right: 0;
            }

            .aa-InputWrapperPrefix {
              .aa-SubmitIcon {
                display: none;
              }

              .aa-Label[hidden] {
                display: block;
              }
            }

            .aa-InputWrapperSuffix, svg.aa-LoadingIcon {
              display: none;
            }
          }
        }

      }

      .aa-Panel.search-editorial__modules {
        position: unset;
        box-shadow: none;
        max-height: calc(85vh - 100px);
        min-height: 15rem;
        overflow-y: scroll;
        margin-top: 0;

        // this selector is needed in order to remove min-height if no recent searches are rendered
        &:not(:has(.aa-Source[data-autocomplete-source-id="recentSearchesPlugin"])) {
          min-height: unset;
        }

        &::-webkit-scrollbar {
          @media (min-width: 1024px) {
            width: 0.6rem;
          }
        }

        &::-webkit-scrollbar-track {
            background: var(--white);
        }
        &::-webkit-scrollbar-thumb {
          background-color: var(--dark-grey);
          border-radius: 0.3rem;
          border: 0.6rem solid var(--dark-grey);
        }

        .aa-PanelLayout {
          overflow-y: hidden;
          flex-direction: column;
          width: 100rem;
          margin: 4.8rem auto 0;
          padding: 0;

          @media (min-width: 1024px) {
            padding: 0 0.3rem;
          }

          .aa-Source {
            margin-bottom: 4rem;

            &[data-autocomplete-source-id="recentSearchesPlugin"] {
              order: 3;
              margin-bottom: 5.6rem;

              .aa-Item {
                min-height: unset
              }
            }

            &[data-autocomplete-source-id="products"] {
              width: fit-content;

              &:has(.aa-SourceNoResults) {
                margin-bottom: 3.2rem;
              }

              &:has(.hideWrapper),
              &:has(.aa-SourceNoResults) .aa-SourceHeader {
                display: none;
              }
            }

            &[data-autocomplete-source-id="recentProducts"] {
              order: 2;
            }

            .aa-SourceHeader {
              margin: 0 0 1.6rem 0;
            }

            .aa-SourceNoResults, .aa-SourceHeader .search-editorial__title {
              padding: 0;
            }

            .aa-Item {
              padding: 0;

              .search-product--info--price {
                margin-top: .8rem;

                span {
                  font-size: 1.2rem;
                }
              }

              &[aria-selected=true] {
                background-color: transparent;
              }

              .aa-ItemContentTitle {
                font-size: 1.4rem;
              }
            }

            #autocomplete-0-querySuggestionsPlugin-list,
            #autocomplete-0-recentSearchesPlugin-list{
              display: flex;
              gap: 3.2rem;

              .aa-ItemLink {
                cursor: pointer;
              }
            }

            #autocomplete-0-querySuggestionsPlugin-list {
              gap: 1rem;

              .aa-ItemLink {
                padding: .9rem 1.6rem;
                border: 1px solid var(--main-color);

                .aa-ItemContentTitle {
                  margin: 0;
                }
              }
            }

            #autocomplete-0-products-list,
            #autocomplete-0-recentProducts-list {
              display: flex;

              .aa-Item {
                border: 1px solid var(--main-color);
                margin-right: -.1rem;
                border-radius: 0;
                width: 18.8rem;

                .search-product {
                  margin-bottom: 0;

                  .search-product--info {
                    padding: 1.4rem 1.2rem;
                    width: 18.8rem;
                    flex-flow: column;

                    .search-product--name {
                      line-height: 1.2rem;
                      font-size: 1.2rem;
                      letter-spacing: .02rem;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    }

                    .aa-sales-price {
                      color: var(--black);
                    }
                  }

                  .search-product--image {
                    picture {
                      width: 18.6rem;
                      height: 23.3rem;
                      border: none;

                      img {
                        object-fit: contain;
                      }
                    }
                  }
                }
              }
            }
          }

          &:has(.aa-SourceNoResults div:not(.hideWrapper)) {
            margin-top: 3.2rem;

            [data-autocomplete-source-id="querySuggestionsPlugin"] {
              order: 1;
            }
          }

          &:not(:has([data-autocomplete-source-id="recentSearchesPlugin"])) {
            &:has(.no-results) {
              margin-top: 3.2rem;
            }

            [data-autocomplete-source-id="products"] {
              margin-bottom: 6.4rem;
            }
          }

          &:has(#autocomplete-0-products-list) {
            .aa-Source[data-autocomplete-source-id="recentProducts"] {
              display: none;
            }
          }
        }
      }
    }

    .editorial__category {
      order: 5;
      width: 100%;
      max-width: 100rem;
      margin-left: auto;
      margin-right: auto;
      margin-top: 4.8rem;

      .editorial-category--title {
        margin-top: 0;
        font-size: 2rem;
        font-weight: 500;
      }

      .editorial-content {
        width: 100%;
        max-width: 100rem;

        ul {
          flex-wrap: unset;
          gap: 2.2rem;
          margin: 0;
        }

        .editorial-box {
          max-width: unset;
          margin-bottom: 7.2rem;
          padding: 0;
          flex-shrink: 1;
          a {
            height: 24.7rem;

            img {
              height: calc(100% - 3.2rem);
            }

            span {
              font-family: franklin-gothic-compressed, sans-serif;
              font-weight: 400;
              font-size: 2.2rem;
              font-style: normal;
              height: 3.2rem;
              justify-content: space-between;
              align-items: center;
              padding-top: .5rem;

              &:before {
                left: -.1rem
              }

              &:after {
                padding-top: 0;
                line-height: 2.5rem;
              }
            }
          }
        }
      }
    }

    &:has(.aa-PanelLayout) {
      .editorial__category {
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 1024px) {
  body:has(.pd-editorial-header_bar:not(.d-none)) {
    .header_navigation:has(#search-navbar .aa-Autocomplete) #search-navbar {
      #search-navdialog {
        padding-top: 4rem;
      }
    }
  }

  .header_navigation:has(#search-navbar .aa-Autocomplete) #search-navbar #search-navdialog {
    z-index: 100;
    padding: 0;

    .navigation-search {
      padding: 0;
      margin-top: 0;

      .site-search {
        padding: 5.6rem 1.6rem 0;
      }

      .aa-Panel.search-editorial__modules,  {
        max-height: fit-content;

        .aa-PanelLayout{
          max-height: unset;
          height: 100%;
          margin: 4rem auto 0;

          &:not(:has([data-autocomplete-source-id="recentSearchesPlugin"])) {
           margin-top: 4rem;

            [data-autocomplete-source-id="products"] {
              margin-bottom: 3.7rem;

              .aa-SourceFooter {
                .search-editorial__title {
                  margin-top: 2.4rem
                }
              }
            }
          }

          &:has(.aa-SourceNoResults div:not(.hideWrapper)) {
            margin-top: 1.6rem;
            .aa-SourceFooter {
              display: none;
            }
          }

          .aa-Source {
            .aa-SourceNoResults,
            &:not([data-autocomplete-source-id="products"], [data-autocomplete-source-id="recentProducts"]),
            &[data-autocomplete-source-id="products"] .aa-SourceHeader,
            &[data-autocomplete-source-id="recentProducts"] .aa-SourceHeader {
              padding: 0 1.6rem 0;
            }

            &[data-autocomplete-source-id="products"] {
              width: 100%;
            }

            #autocomplete-0-products-list, #autocomplete-0-recentProducts-list {
              display: grid;
              border-top: .1rem solid var(--main-color);
              margin: 0 auto;
              grid-template-columns: repeat(2, 1fr);
              width: 100%;

              .aa-Item {
                width: 100%;
                margin-right: 0;
                border: 0;
                border-bottom: .1rem solid var(--main-color);
                border-right: .1rem solid var(--main-color);
                .search-product--image,
                .search-product .search-product--image .product-img-mini-bag {
                  width: 100%;
                  height: auto;
                }
              }
            }

            .aa-SourceHeader div {
              margin-top: 0;
            }
          }
        }
      }

      .aa-Close {
        top: -0.1rem;
        right: -0.1rem;
      }
    }

    .editorial__category .editorial-content ul {
      flex-flow: column;
      gap: 1.6rem;
      margin-bottom: 5.6rem;

      .editorial-box {
        margin-bottom: 0;
        max-width: 100%;
      }
    }
  }
}
