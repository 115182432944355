.algolia-all-facets-container-placeholder{
  .accordion-filters{
    &:first-child {
      .search-refinement__group{
        .search-refinement__title {
          border-top: 0;
        }
      }
    }
    .search-refinement__group{
      .search-refinement__title {
        border-top: 1px solid var(--light-grey);
        margin-left: 0;
      }
    }
  }
}


.ais-InstantSearch {

  .refinement-bar {
    padding-left: 2rem;
  }

  .filters-active {
    #current-refinements {
      gap: .8rem;
    }

    .search-filters__actions {
      padding: 0;
    }

    .ais-ClearRefinements-button {
      color: var(--main-color);
    }

  }
}

#algolia-filterbar-clear-refinements-placeholder {
  padding-left: 0;

  .aa-ApplyFilter {
    order: 2;
  }
}

.checkbox__container.checkbox__container--toggle .form-element__label_checkbox {
  text-decoration: none;

  &.checked-primary-category {
    background-color: var(--main-color);
    color: var(--white);
  }
}

body:has(header.header-sticky) {
  .ais-InstantSearch .search-filters {
    z-index: 100;
  }
}
