#algolia-hits-placeholder {
  justify-content: center;

  .algolia-grid {
    border-left: 1px solid var(--main-color);

    .add-to-wish-list {
      z-index: 10;
    }

    .product-tile-body {
      .pdp-link a {
        text-decoration: none;
        font-size: 1.2rem;
        color: var(--black);
      }

      .aa-price {
        margin-bottom: 1.5rem;
        .aa-sales-price {
          font-size: 1.4rem;
          color: var(--black);
          width: 100%
        }
      }
    }
  }

  #algolia-pagination {
    flex-direction: column;
    margin: 6rem 0 3rem 0;

    &:has(.d-none) {
      margin: 0;
    }

    .pagination__counter {
      margin: 0;
    }

    .pagination__holder {
      width: 100%;
      gap: .4rem;
      margin: 1.6rem 0 0 0;

      .pagination-item {
        color: var(--black);
        padding: .5rem;
        text-decoration: none;

        &.page-active {
          color: var(--main-color);
        }
      }

      .arrow-container {
        line-height: .5rem;

        &:not(.disabled) {
          cursor: pointer;
        }

        &#arrow-left-container {
          rotate: 180deg;
        }

        &.disabled svg{
          fill-opacity: .3;
        }

        svg {
          margin: 0 1.2rem;
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    #algolia-pagination {
      margin-top: 4rem;
      margin-bottom: .55rem;
    }
  }
}


